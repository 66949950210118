<template>
    <div class="pd20 bg-w">
        <div class="xhm-table-gray mt20">
            <a-table rowKey="version_id" :columns="columns" :pagination="pagination" @change="change"
                :data-source="list" :loading="loading">
            </a-table>
        </div>
    </div>
</template>

<script>
import { apiVersionList } from '../../utils/js/api';
export default {
    data() {
        return {
            loading: false,
            pagination: {
                current: 1,
                pageSize: 10, //每页中显示10条数据
                total: 0,
            },
            labelCol: { span: 6 },
            wrapperCol: { span: 16 },
            columns: [
                { title: '版本id', dataIndex: 'version_id', align: 'center' },
                { title: '版本号', dataIndex: 'version', align: 'center' },
                { title: '类型', dataIndex: 'miniapp_type', align: 'center' },
                { title: '模板ID', dataIndex: 'template_id', align: 'center' },
                { title: '描述', dataIndex: 'version_desc', align: 'center' },
                { title: '添加时间', dataIndex: 'create_time', align: 'center' },
            ],
            list: [],
        }
    },
    created() {
        this.getList();
    },
    methods: {
        getList() {
            if (this.loading == true) return;
            this.loading = true;
            apiVersionList({
                limit: this.pagination.pageSize,
                page: this.pagination.current
            }).then(res => {
                this.pagination.total = res.total;
                this.list = res.list;
                this.loading = false;
            }).catch(res => {
                console.log(res);
                this.loading = false;
            })
        },
        change(pagination, filters, sorter) {
            this.pagination.current = pagination.current;
            this.getList();
        },
    }
}
</script>

<style></style>
